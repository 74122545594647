<mat-tab-group mat-align-tabs="center">
  <mat-tab label="Out">
    <table>
      <tr>
        <th>Marke</th>
        <th>{{brand}}</th>
      </tr>
      <tr>
        <th>Breite</th>
        <th>{{width}}</th>
      </tr>
      <tr>
        <th>Breite/Höhe</th>
        <th>{{heightWidthRatio}}</th>
      </tr>
      <tr>
        <th>Bauart</th>
        <th>{{constructionType}}</th>
      </tr>
      <tr>
        <th>Umfang</th>
        <th>{{rimDiameter}}</th>
      </tr>
      <tr>
        <th>Lastenindex</th>
        <th>{{li}}</th>
      </tr>
      <tr>
        <th>Geschwindichkeitsindex</th>
        <th>{{vi}}</th>
      </tr>
    </table>
  </mat-tab>
  <mat-tab label="Raw">{{raw}}</mat-tab>
  <mat-tab label="Images">
    <carousel [src]="debugImgPreview" class="result"></carousel>
  </mat-tab>
</mat-tab-group>
