import{Injectable}from'@angular/core';
import {HttpClient, HttpHeaders, HttpParams}from '@angular/common/http';
import {Result} from 'src/app/domain/result';
import { environment } from '../../environments/environment';


@Injectable({
providedIn: 'root'
})
export class TireService {
constructor(private http: HttpClient) { }

  url = environment.apiEndpoint;

  getTireData() {
    return this.http.get<void>(this.url);
  }

  sendImage(image: File){
    const formData: FormData = new FormData();
    formData.append("file", image, image.name);

    var headers = new HttpHeaders({
        'reportProgress': 'true',
        'Accept': 'application/json',
    });

    return this.http.post<Result>(this.url+'/detectWholeTire', formData, {headers})
  }

  sendImages(images: Array<File>){

    const formData = new FormData();
    images.forEach((file) => { formData.append('files', file); });

    var headers = new HttpHeaders({
        'reportProgress': 'true',
        'Accept': 'application/json',
    });

    return this.http.post<Result>(this.url+'/detectText', formData, {headers})
  }

  getDebugImage(uuid: String, id: number) {

    var headers = new HttpHeaders({
        'responseType': 'blob'
    });

    return this.http.get(this.url + '/debug_img' + '?uuid_str='+ uuid +'&id='+id, {responseType: 'blob', headers: headers});
  }
}
