<div class="row">
  <div class="col-8">
    <label class="btn btn-default p-0">
      <input type="file" accept="image/*" (change)="selectFile($event)" multiple>
    </label>
  </div>
</div>

<div>
  <carousel [src]="preview" class="preview"></carousel>
</div>

<div *ngIf="currentFile && progress" class="progress my-3">
  <div
    class="progress-bar progress-bar-info"
    role="progressbar"
    attr.aria-valuenow="{{ progress }}"
    aria-valuemin="0"
    aria-valuemax="100"
    [ngStyle]="{ width: progress + '%' }"
  >
    {{ progress }}%
  </div>
</div>
