<c-carousel *ngIf="isVisible"  [interval]="0">
  <c-carousel-inner>
    <c-carousel-item *ngFor="let slide of slides">
      <img
        alt="title"
        class="d-block w-100"
        loading="lazy"
        src="{{slide.src}}"
      />
      <c-carousel-caption class="d-none d-md-block">
        <h3>{{slide.title}}</h3>
        <p>{{slide.subtitle}}</p>
      </c-carousel-caption>
    </c-carousel-item>
  </c-carousel-inner>
  <c-carousel-control [routerLink] caption="" direction="prev"></c-carousel-control>
  <c-carousel-control [routerLink] caption="" direction="next"></c-carousel-control>
</c-carousel>
