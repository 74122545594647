<div fxLayout="column">

<div fxLayout="row" fxLayoutAlign="center">
  <div fxFlex="35" class="img-upload-left">
    <app-image-upload (newImageEvent)="newImage($event)"></app-image-upload>
  </div>

  <div fxFlex="35" class="tab-sheet">
    <api-result [result]="result" [debugImg]="debugImg"></api-result>
  </div>
</div>
<div fxLayout="row" fxLayoutAlign="center">
  <div fxFlex="70" >
    <button mat-fab extended (click)="sendImages()">
      Abschicken
    </button>
  </div>
</div>
</div>
