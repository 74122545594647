<div fxLayout="row" fxLayoutAlign="center">
  <div fxFlex="40">
      <h1 class="center">Reifenerkennung Demo</h1>
      <br>
      <hr>
  </div>

</div>
<main></main>
<router-outlet></router-outlet>
