import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { MatTabsModule } from '@angular/material/tabs';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { ImageUploadComponent } from './components/image-upload/image-upload.component';
import { ApiResultComponent } from './components/api-result-tab-sheet/api-result.component';
import { TestingComponent } from './components/demo-component/demo.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ImgCarouselComponent } from './components/carousel/carousel.component';
import { CarouselModule } from '@coreui/angular';


@NgModule({
  declarations: [
    AppComponent,
    ImageUploadComponent,
    ApiResultComponent,
    TestingComponent,
    ImgCarouselComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    MatTabsModule,
    FlexLayoutModule,
    BrowserAnimationsModule,
    CarouselModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
