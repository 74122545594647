import{Component, OnInit, Output, EventEmitter}from '@angular/core';
import {HttpEventType, HttpResponse}from '@angular/common/http';
import {Observable} from 'rxjs';
import {FileUploadService} from 'src/app/services/file-upload.service';

@Component({
selector: 'app-image-upload',
templateUrl: './image-upload.component.html',
styleUrls: ['./image-upload.component.scss'],
})
export class ImageUploadComponent implements OnInit {
selectedFiles?: FileList;
currentFile?: File;
progress = 0;
message = '';
preview = '';
previews: String[] = [];
@Output() newImageEvent = new EventEmitter<File>();

imageInfos?: Observable < any>;

constructor(private uploadService: FileUploadService) {}

  ngOnInit(): void {
    this.imageInfos = this.uploadService.getFiles();
  }

  selectFile(event: any): void {
    this.message = '';
    this.preview = '';
    this.progress = 0;
    this.selectedFiles = event.target.files;

    if (this.selectedFiles) {
      const file: File | null = this.selectedFiles.item(0);
      Array.from(this.selectedFiles).forEach((file, i) => this.loadFile(file, i));
    }
  }

  loadFile(file: File, index: number): void{
        this.preview = '';
        this.currentFile = file;

        const reader = new FileReader();

        reader.onloadend = (e: any) => {
          this.preview = e.target.result;
          //this.previews[index] = e.target.result;
          //console.log(this.previews);
          this.previews.push(e.target.result);
          this.newImageEvent.emit(file);
        };

        reader.readAsDataURL(this.currentFile);
  }

  upload(): void {
    this.progress = 0;

    if (this.selectedFiles) {
      const file: File | null = this.selectedFiles.item(0);

      if (file) {
        this.currentFile = file;

        this.uploadService.upload(this.currentFile).subscribe({
          next: (event: any) => {
            if (event.type === HttpEventType.UploadProgress) {
              this.progress = Math.round((100 * event.loaded) / event.total);
            } else if (event instanceof HttpResponse) {
              this.message = event.body.message;
              this.imageInfos = this.uploadService.getFiles();
            }
          },
          error: (err: any) => {
            console.log(err);
            this.progress = 0;

            if (err.error && err.error.message) {
              this.message = err.error.message;
            } else {
              this.message = 'Could not upload the image!';
            }

            this.currentFile = undefined;
          },
        });
      }

      this.selectedFiles = undefined;
    }
  }
}
