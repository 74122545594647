import{Component, OnInit, AfterViewInit, ViewChild, Input, Output}from '@angular/core';
import {HttpEventType, HttpResponse, HttpClientModule}from '@angular/common/http';
import {Observable}from 'rxjs';
import {ImageUploadComponent}from '../image-upload/image-upload.component';
import {ApiResultComponent} from '../api-result-tab-sheet/api-result.component';
import {TireService}from 'src/app/services/tire.service';
import {Result}from 'src/app/domain/result';


@Component({
selector: 'main',
templateUrl: './demo.component.html',
styleUrls: ['./demo.component.scss']
})
export class TestingComponent{
name = 'Test';
file!: File;
files: Array < File> = Array();
resultRaw = '';
result!: Result
child1!: ImageUploadComponent;
child2!: ApiResultComponent;
debugImg!: File;
debugImgs: File[] = [];

constructor(private tireService: TireService) {}

  sendImages(): void {
    this.tireService.sendImages(this.files).subscribe((data: Result) => {
      this.result = data;
      for(var i = 0; i < this.files.length; i++) {
        this.tireService.getDebugImage(this.result.debug_uuid, i).subscribe((img: Blob) => {

            const imageFile = new File([img], 'image.png', {
              type: img.type,
            });
              this.debugImg = imageFile
            }
          );
        }
      }
    );
  }

  newImage(img: File): void {
    this.file = img;
    this.files.push(img);
  }
}
