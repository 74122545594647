import { Component, OnInit, Input, ChangeDetectorRef} from '@angular/core';

@Component({
  selector: 'carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class ImgCarouselComponent {

  slides: Array<any> = new Array();
  index: number = -1;
  public isVisible: boolean = true;

  constructor(private ref: ChangeDetectorRef){};


  imgs: Array<any> = Array(0);
  //img: Array<any> = Array(0);
  @Input() img = '';

  @Input() set src(img: String){
    this.isVisible = false;
    this.addImg(img);
    this.isVisible = true;
  }

  addImg(img: String): void {
    if(this.index >= 0){
      //this.slides[this.index].id = this.index;
      //this.slides[this.index].src = img;
      this.imgs.push(img);
      this.slides.push({id: this.index, src: img, title:"", subtitle:""});
      console.log(this.slides);
      this.ref.detectChanges();
      this.ref.markForCheck();
    }
    this.index++;
  }
}
