import{Component, OnInit, Input}from '@angular/core';
import {HttpEventType, HttpResponse}from '@angular/common/http';
import {Observable}from 'rxjs';

@Component({
selector: 'api-result',
templateUrl: './api-result.component.html',
styleUrls: ['./api-result.component.scss']
})
export class ApiResultComponent{

raw: String = '';
_debugImg!: File;
debugImgPreview = '';
brand = '-';
width = '-';
heightWidthRatio = '-';
rimDiameter = '-';
constructionType = '-';
li = '-';
vi = '-';
dot = '-';
previews: String[] = [];

@Input() set result(raw: any){
this.raw = JSON.stringify(raw, null, 2);
this.brand = raw.tire.brand?.value;
const tireSize = raw.tire.tireSize?.value
if(tireSize == null){
this.width = '-';
this.heightWidthRatio = '-';
this.rimDiameter = '-';
this.constructionType = '-';
this.li = '-';
this.vi = '-';
this.dot = '-';
}else{
this.width = tireSize.width;
this.heightWidthRatio = tireSize.heightWidthRatio;
this.constructionType = tireSize.constructionType;
this.rimDiameter = tireSize.rimDiameter;
this.li = tireSize.li;
this.vi = tireSize.velocityIndex;
}
}


@Input() set debugImg(image: File) {
this._debugImg = image;

const reader = new FileReader();

reader.onload = (e: any) => {
this.debugImgPreview = e.target.result;
this.previews = [e.target.result];
};

reader.readAsDataURL(image);
  }
}
